
const blukai = {
    function: function (w, key, value) {
        if (this.ok) {
            console.log('blukai:---', key, value)
            w.bk_addPageCtx(key, value);
            w.bk_doJSTag(94856, 1);
        }
    }
}

export default blukai;
